
// 假期类型
export const categoryType: any = {
  'Annual': '年假',
  'Sick': '病假',
  'Compassionate': '事假',
  'Compensatory': '调休假',
  'Marriage': '婚假',
  'Maternity': '产假',
  'Paternity': '陪产假',
  'Bereavement': '丧假',
  'Home': '在家办公',
  'OverTime': '加班',
  'DailyOverTime': '日常加班',
  'HolidayOverTime': '周末加班',
  'FestivalOverTime': '节日加班',
  'TotalAnnualLimit': '年假总额度',
  'RestToSalary': '调休假换薪',
  'Parental':'育儿假',
  'AnnualToSalary':'年假换薪',
  'DailyOverTimeToSalary':'日常加班换薪',
  'ProjectOverTimeToSalary':'项目加班换薪',
  'ProjectOverTime':'项目加班',
}
export const overtimeNatureType:any={
  'WorkDayOverTime':'工作日',
  'HolidayOverTime':'周末',
  'FestivalOverTime':'法定节假日',
}